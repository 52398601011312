import * as React from "react"
import { graphql } from 'gatsby'

// Hooks

// COmponents
import { Page } from 'components/core/layouts/base';
import { Hero } from 'components/core/hero';
import { Clients } from 'components/services/clients';
import { Services } from 'components/services/service';
import { Process } from 'components/services/process';
import { Testimonies } from 'components/services/testimony';
import { Footer } from 'components/core/footer';
import { ContactWithCurve } from 'components/core/contact';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

function IndexPage({location}){
  const {t} = useTranslation("service");
  return <Page dark={true}  location={location} title={t("metadata.title")} description={t("metadata.description")}>
       <Hero/>
          <div className="w-full bg-slate-50 pb-16">
            <Services/>
            <Process/>
          </div>
          <Clients/>
          <div className="bg-slate-50 w-full py-16">
            <Testimonies/>
          </div>
          <ContactWithCurve/>
          <Footer />
          </Page>
}

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;