import React from "react";

// Utils
import classnames from 'classnames';

// Components
import { StaticImage } from "gatsby-plugin-image"
import { Sphere } from 'components/core/sphere';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

function Client({children, hideOnMobile}){
  return <div className={classnames("h-24 md:h-40 w-2/6 md:w-1/6 lg:w-1/6  ", hideOnMobile? "hidden md:inline-flex": "inline-flex")}>
          <div className="flex justify-center w-full h-full">
            {children}
          </div>
        </div>
}
export function Clients(){
  const {t} = useTranslation("service");
  const maxWidth = 200;
  return <div className="relative pt-16 mx-auto overflow-hidden md:py-16">
            <h3 className="text-center font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{t("clients.title")}</h3>
            <div className="space-y-3 lg:space-y-12 max-w-[1500px] space-x-5 md:space-x-12 mx-auto text-center ">
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/logo-premier-tech.png"} alt={"premier-tech"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-40" objectFit="contain" imgClassName="h-full w-auto"/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/logo-ingeno.png"} alt={"ingeno"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-40" objectFit="contain" imgClassName="h-full w-auto"/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/logo-mayo.png"} alt={"ingeno"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-40  scale-75" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/twenty3.jpg"} alt={"twenty3"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-80" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/xpertsea.jpg"} alt={"xpertsea"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-80" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/curbside.jpg"} alt={"curbside"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-90" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/hortau.jpg"} alt={"hortau"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-60" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/logo-mirego-gray.png"} alt={"mirego"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-50" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/logo-voxmedia.jpg"} alt={"voxmedia"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-60" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/muutaa.jpg"} alt={"muutaa"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-70" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/logo-celestron-gray.png"} alt={"celestron"} style={{maxWidth: maxWidth}} className="w-full max-h-40" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/logos-DAM_lowres.jpeg"} alt={"DAM"} style={{maxWidth: maxWidth}} className="w-full max-h-40" objectFit="contain" imgClassName="h-full w-auto "/></Client>
              <Client><StaticImage placeholder="tracedSVG" src={"../../images/logo-vendelux.png"} alt={"Vendelux"} style={{maxWidth: maxWidth}} className="w-full max-h-40 opacity-50" objectFit="contain" imgClassName="h-full w-auto"/></Client>
              <Client><StaticImage class="grayscale" placeholder="tracedSVG" src={"../../images/logo-insurloop.png"} alt={"celestron"} style={{maxWidth: maxWidth}} className="w-full max-h-40" objectFit="contain" imgClassName="h-full w-auto "/></Client>

            </div>
            <div className="absolute bottom-0 w-full h-16 bg-gradient-to-t from-slate-50 via-slate-50 to-transparent ld:hidden"/>

            <Sphere speed={0.3} radius={50} offset={0} sphereId="client-sm-2" color="dark-blue" className="top-0 justify-start" svgClassName={"lg:hidden translate-y-[20rem] translate-x-[1em]"}/>
            <Sphere speed={-0.15} radius={200} offset={0} sphereId="client-sm-1" color="dark-blue" className="top-0 justify-end" svgClassName={"lg:hidden translate-x-1/2 translate-y-1/2"}/>
            <Sphere speed={0.15} radius={300} offset={0} sphereId="client-1" color="dark-blue" className="top-0 justify-end" svgClassName={"hidden lg:block translate-y-1/2 translate-x-[4rem]"}/>

         </div>
} 